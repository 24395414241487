import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1005.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1005.000000) scale(0.100000,-0.100000)">
<path d="M0 5025 l0 -5025 1700 0 c1089 0 1700 4 1700 10 0 5 -10 10 -22 10
-241 -3 -1354 11 -1553 19 -148 6 -394 16 -545 21 -423 15 -700 33 -709 44
-11 15 -30 358 -36 686 -7 296 -11 432 -20 630 -10 233 -13 1270 -3 1270 5 0
5 7 -1 18 -5 10 -8 114 -6 250 4 206 -1 263 -18 220 -3 -7 -6 1 -6 19 -1 25 2
31 14 27 13 -5 15 25 11 251 -3 201 -7 260 -18 266 -9 7 -10 11 -2 17 16 10
19 40 9 82 l-9 35 -7 -35 c-3 -20 -7 24 -8 102 0 75 3 139 8 142 17 11 21 67
6 103 -12 28 -15 82 -13 272 1 142 4 204 7 156 l6 -80 8 72 c5 39 6 76 3 81
-3 6 -7 111 -7 233 -1 145 -5 212 -10 189 -5 -19 -7 -120 -5 -225 3 -142 2
-167 -4 -100 -13 141 -11 275 4 366 11 68 14 274 14 1054 1 534 4 978 8 988
11 30 -4 32 -27 4 -12 -17 -30 -27 -46 -27 -21 0 -24 2 -13 15 7 8 19 15 26
15 8 0 14 8 14 17 0 13 -3 14 -12 5 -9 -9 -15 -7 -25 6 -13 15 -14 14 -8 -11
4 -21 2 -28 -7 -25 -7 3 -13 16 -13 29 0 20 5 24 26 22 24 -1 25 1 16 21 -9
20 -13 21 -25 10 -19 -20 -22 -18 -22 17 0 17 4 28 10 24 6 -3 10 -2 10 4 0 5
-6 11 -12 14 -10 3 -13 38 -12 133 1 146 10 227 25 218 6 -3 8 -12 5 -20 -3
-8 0 -11 9 -7 11 3 13 13 8 32 -6 23 -5 25 10 12 10 -7 17 -21 17 -30 0 -34
40 -81 87 -104 27 -12 51 -26 55 -32 11 -15 10 -37 -2 -30 -5 3 -10 2 -10 -3
0 -13 49 -37 57 -27 5 4 2 10 -5 12 -6 3 -12 15 -12 27 0 26 -34 66 -56 66
-25 0 -84 31 -78 41 5 9 -55 132 -68 137 -15 7 -8 22 10 19 29 -4 39 -15 46
-49 6 -31 7 -32 21 -11 10 14 13 32 9 52 -6 30 -24 44 -24 19 0 -10 -3 -9 -9
1 -5 7 -6 23 -3 34 5 20 6 20 14 1 5 -10 6 198 3 466 -6 615 12 1640 29 1658
9 9 70 13 222 16 336 7 661 16 769 21 55 3 276 7 490 10 215 3 460 7 545 10
216 7 1196 16 2280 22 503 3 922 9 930 14 8 5 -1262 9 -2867 9 l-2883 0 0
-5025z m477 3173 c-1 -40 -3 -8 -3 72 0 80 2 112 3 73 2 -40 2 -106 0 -145z
m0 -210 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-10 -1910
c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m13 -363 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-13 -117
c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m0 -90 c-2 -13 -4 -3
-4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m10 -200 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m0 -1165 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7
2 -21 0 -30z m10 -450 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m0 -250 c-2 -32 -3 -8 -3 52 0 61 1 87 3 58 2 -29 2 -78 0 -110z m0 -175 c-2
-13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z"/>
<path d="M5806 10041 c5 -5 455 -11 1004 -15 547 -3 1069 -8 1160 -10 91 -3
318 -8 505 -11 187 -3 446 -7 575 -10 129 -3 399 -7 600 -10 489 -8 669 -13
682 -21 7 -5 9 -129 4 -412 -3 -223 -7 -744 -8 -1156 -2 -423 -5 -644 -8 -506
-4 212 -6 168 -13 -330 -4 -316 -10 -656 -13 -755 -2 -99 -6 -399 -7 -667 -1
-269 -5 -488 -9 -488 -4 0 -7 -42 -7 -92 0 -79 -12 -975 -15 -1048 0 -14 3
-37 7 -52 6 -22 4 -26 -8 -22 -18 7 -20 -9 -2 -24 8 -7 12 -45 12 -123 0 -81
-3 -113 -12 -116 -18 -6 -18 -199 0 -206 10 -4 10 -7 0 -18 -15 -15 -17 -56
-3 -61 6 -2 6 -18 0 -43 -11 -45 -20 -198 -23 -387 -2 -98 1 -129 11 -133 8
-3 10 0 6 8 -4 6 2 3 14 -7 25 -22 29 -38 8 -30 -8 3 -17 1 -21 -5 -3 -7 2
-11 14 -11 10 0 21 -4 23 -10 2 -5 19 4 38 21 l34 30 9 -23 c9 -24 10 -101 5
-323 l-3 -130 -28 -1 c-18 -1 -31 5 -35 15 -3 9 -18 35 -34 58 -30 44 -36 68
-15 60 7 -3 12 4 12 19 0 13 -5 24 -11 24 -13 0 -24 23 -24 51 0 10 -4 19 -9
19 -12 0 -19 -87 -11 -120 4 -14 2 -53 -4 -88 -8 -49 -7 -68 3 -84 10 -16 10
-22 1 -28 -10 -6 -10 -15 -1 -41 8 -23 8 -35 1 -37 -6 -2 -10 -46 -10 -110 0
-59 -5 -174 -10 -257 -9 -134 -8 -151 6 -162 14 -10 15 -17 5 -40 -6 -15 -10
-46 -10 -68 4 -88 -31 -1178 -46 -1465 -3 -52 -8 -119 -11 -148 -4 -28 -4 -52
-1 -52 4 0 15 19 25 43 31 68 76 96 94 57 18 -39 -5 -428 -26 -442 -7 -5 -7
-8 2 -8 6 0 12 -6 12 -14 0 -7 -10 -13 -22 -12 -17 1 -23 7 -24 26 0 14 -7 39
-14 56 -12 27 -16 29 -36 20 -18 -9 -23 -21 -26 -61 -5 -55 -1 -67 12 -45 7
12 12 12 22 2 9 -9 90 -12 330 -12 l318 0 0 5025 0 5025 -2502 0 c-1439 0
-2498 -4 -2492 -9z m4664 -216 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6
-6 -2 -15z m-53 -147 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-90 -2170 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-10 -640
c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m0 -90 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-60 -2710 c-2 -13 -4 -3 -4 22 0 25
2 35 4 23 2 -13 2 -33 0 -45z m-10 -400 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2
-13 2 -33 0 -45z m0 -175 c-2 -16 -4 -3 -4 27 0 30 2 43 4 28 2 -16 2 -40 0
-55z m0 -65 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m111 8 c-1
-14 -6 -22 -10 -20 -4 3 -8 0 -8 -5 0 -6 6 -11 13 -12 10 0 10 -2 0 -6 -7 -2
-13 -11 -13 -18 0 -13 -29 -29 -38 -21 -7 8 17 68 36 87 22 23 22 23 20 -5z
m-151 -1098 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m0 -85 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z"/>
<path d="M5350 8224 c-25 -15 -85 -50 -135 -79 -49 -29 -142 -83 -205 -120
-63 -37 -128 -76 -145 -85 -16 -9 -104 -60 -195 -112 -91 -53 -181 -104 -200
-115 -19 -11 -129 -75 -245 -143 -115 -68 -284 -165 -375 -217 -91 -52 -186
-108 -212 -125 l-48 -30 0 -218 0 -219 88 53 c162 99 313 186 320 186 4 0 24
11 44 25 20 14 45 25 55 25 9 0 170 -92 358 -204 187 -112 498 -298 690 -413
193 -115 429 -256 525 -313 96 -58 183 -109 193 -115 9 -5 104 -61 210 -124
415 -247 459 -273 462 -269 1 1 6 98 10 214 l7 210 -109 65 c-245 148 -615
371 -716 433 -59 36 -110 66 -112 66 -3 0 -29 16 -60 35 -30 20 -205 126 -388
237 -182 110 -372 226 -422 256 -49 30 -123 73 -163 96 -42 24 -71 47 -70 55
3 14 205 137 413 251 33 18 65 36 70 40 6 4 27 17 48 29 20 12 54 31 75 42 20
12 62 36 92 52 30 17 71 40 90 52 19 12 42 25 50 28 8 4 29 16 45 27 17 11 55
33 85 50 30 17 62 34 70 40 75 46 208 120 218 120 29 0 5 22 -90 82 -195 123
-255 158 -269 158 -8 0 -34 -12 -59 -26z"/>
<path d="M5915 7804 c-71 -42 -168 -99 -215 -127 -47 -27 -105 -61 -130 -75
-25 -14 -126 -72 -225 -130 -99 -57 -200 -116 -225 -130 -25 -14 -62 -36 -83
-49 l-38 -24 133 -81 c73 -44 147 -89 163 -98 36 -20 244 -146 370 -224 79
-48 197 -119 299 -180 59 -35 551 -330 686 -411 l135 -81 3 -441 2 -441 -42
-24 c-24 -14 -117 -67 -208 -120 -91 -53 -183 -106 -205 -118 -22 -12 -103
-58 -180 -102 -104 -60 -143 -78 -155 -71 -8 6 -35 21 -60 35 -82 47 -214 124
-284 166 -37 23 -70 42 -72 42 -3 0 -33 17 -67 38 -54 34 -151 91 -367 217
-30 18 -149 89 -265 157 -115 69 -226 134 -245 145 -45 26 -355 209 -397 235
l-33 20 0 -231 0 -230 113 -66 c61 -37 144 -85 182 -108 39 -22 90 -52 115
-67 25 -15 106 -62 180 -105 74 -43 146 -85 160 -94 14 -9 34 -21 45 -26 11
-6 58 -34 105 -62 47 -27 128 -75 180 -105 52 -31 226 -132 385 -225 160 -94
300 -175 313 -182 20 -11 31 -8 90 25 37 20 92 50 122 67 30 16 60 33 65 37 6
4 35 21 65 38 30 16 168 92 305 169 138 77 273 153 300 168 28 15 75 41 105
59 30 17 69 38 85 47 38 21 43 52 44 279 1 91 5 242 11 335 5 94 12 305 16
470 l6 300 -193 118 c-107 65 -201 122 -209 128 -8 5 -116 70 -240 144 -124
75 -232 140 -240 145 -8 5 -33 20 -55 33 -22 13 -89 54 -150 92 -145 89 -160
98 -227 137 -32 18 -58 36 -58 39 0 11 30 32 122 85 l90 52 116 -65 c65 -36
124 -71 132 -78 8 -6 62 -38 120 -70 58 -32 116 -66 130 -75 14 -10 45 -28 70
-42 25 -14 106 -60 180 -103 74 -43 182 -105 240 -137 58 -33 122 -71 143 -84
33 -22 37 -22 37 -7 0 10 2 117 4 238 l5 220 -62 36 c-34 20 -84 49 -112 64
-47 26 -229 134 -393 231 -40 24 -77 44 -82 44 -6 0 -10 5 -10 10 0 6 -4 10
-10 10 -5 0 -120 65 -255 145 -135 80 -247 145 -248 145 -1 0 -60 -34 -132
-76z"/>
<path d="M393 7530 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M391 7394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M512 7339 c29 -16 90 -16 95 1 4 12 3 13 -5 1 -8 -11 -16 -11 -42 -2
-18 6 -40 11 -49 11 -14 -1 -14 -2 1 -11z"/>
<path d="M452 7270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4227 6933 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M6450 5300 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M485 4455 c-4 -42 -5 -78 -2 -81 2 -3 8 29 12 71 4 42 5 79 2 81 -3
3 -8 -29 -12 -71z"/>
<path d="M480 4253 c0 -28 3 -34 10 -23 13 20 13 60 0 60 -5 0 -10 -17 -10
-37z"/>
<path d="M481 3954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6474 3764 c-57 -20 -118 -75 -146 -132 -21 -42 -24 -65 -28 -222
l-5 -175 -165 0 -165 0 -3 157 c-2 147 -1 159 18 178 11 11 32 20 46 20 14 0
23 4 19 10 -4 6 -79 10 -191 10 -121 0 -183 -3 -179 -10 3 -5 17 -10 30 -10
13 0 32 -10 42 -23 17 -22 18 -44 10 -408 -8 -357 -10 -387 -29 -427 -18 -38
-75 -92 -111 -105 -26 -9 4 -19 76 -24 65 -5 82 -3 121 17 54 26 90 69 117
140 15 41 21 88 26 236 l6 184 42 0 c23 0 97 -3 163 -7 l122 -6 0 -151 c-1
-166 -5 -182 -58 -192 -74 -15 -8 -24 166 -23 155 0 182 3 156 12 -68 26 -65
4 -56 429 8 439 8 438 94 493 26 17 47 33 47 38 1 13 -122 6 -165 -9z"/>
<path d="M4197 3690 c-41 -11 -97 -37 -97 -46 0 -3 17 -3 38 1 45 7 87 -7 125
-43 36 -34 80 -119 79 -152 0 -14 -21 -67 -46 -118 -25 -51 -46 -95 -46 -97 0
-6 -41 -96 -105 -231 -52 -110 -88 -154 -126 -154 -11 0 -19 -4 -19 -10 0 -6
48 -10 125 -10 70 0 125 4 125 9 0 5 -10 11 -21 13 -42 6 -45 30 -12 109 l29
74 123 0 c68 0 126 -2 131 -5 4 -3 24 -45 44 -94 114 -276 314 -402 538 -340
32 9 58 21 58 25 0 5 -24 9 -52 9 -161 1 -237 106 -443 609 -102 251 -123 292
-180 359 -70 81 -172 116 -268 92z m238 -467 c22 -60 41 -114 43 -120 3 -10
-22 -13 -97 -13 -60 0 -101 4 -101 10 0 5 20 55 45 110 25 55 45 105 45 110 0
6 6 10 13 10 7 0 30 -47 52 -107z"/>
<path d="M6590 3663 c0 -5 23 -17 52 -27 107 -38 218 -186 282 -378 24 -70 26
-91 26 -242 0 -153 -2 -168 -20 -186 -11 -11 -29 -20 -39 -20 -11 0 -23 -4
-26 -10 -4 -7 49 -10 153 -10 88 0 174 -3 191 -6 41 -9 40 9 -1 21 -50 15 -59
52 -52 225 8 168 32 257 106 388 53 94 153 200 197 208 17 4 34 11 37 15 8 14
-39 10 -92 -8 -71 -24 -187 -143 -234 -240 -19 -40 -38 -72 -42 -73 -4 0 -19
22 -32 48 -62 123 -177 226 -296 265 -78 26 -210 45 -210 30z"/>
<path d="M4037 3644 c-4 -4 -161 -6 -349 -5 -189 0 -361 -3 -382 -8 -55 -13
-87 -40 -114 -93 -21 -41 -22 -51 -11 -86 12 -42 53 -101 63 -91 4 3 -1 23
-10 44 -19 45 -13 98 13 128 34 38 76 47 213 47 l130 0 0 -329 c0 -182 -4
-341 -9 -354 -10 -25 -43 -43 -68 -39 -7 2 -13 -2 -13 -8 0 -6 65 -10 193 -9
137 1 183 3 160 10 -63 18 -63 18 -56 393 l6 338 90 -4 c88 -4 90 -5 123 -41
23 -25 34 -47 34 -67 0 -16 5 -30 10 -30 6 0 10 42 10 105 0 81 -3 105 -13
105 -8 0 -17 -3 -20 -6z"/>
<path d="M5163 3628 c-61 -10 -90 -25 -131 -66 -61 -61 -79 -160 -43 -237 25
-55 68 -94 197 -176 132 -84 177 -126 193 -179 34 -114 -52 -200 -200 -200
-95 0 -191 62 -233 149 -23 49 -21 147 5 195 21 40 19 57 -5 37 -30 -25 -58
-122 -54 -186 9 -131 81 -209 233 -251 87 -24 190 -13 272 30 18 9 34 16 35
15 10 -5 94 93 114 133 49 96 23 217 -63 299 -21 20 -106 79 -189 132 -171
109 -186 127 -157 186 29 58 109 84 193 61 55 -15 110 -67 110 -106 0 -59 19
-6 25 72 l7 81 -136 9 c-74 5 -152 6 -173 2z"/>
<path d="M10270 3241 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M10190 3221 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M10270 2950 c0 -8 11 -28 24 -45 l24 -30 -1 27 c-1 14 -5 24 -9 22
-4 -2 -14 6 -22 18 -11 15 -16 17 -16 8z"/>
<path d="M3106 2465 c-63 -34 -75 -94 -28 -145 21 -24 38 -32 80 -37 59 -7 93
12 91 51 0 15 -2 16 -6 6 -20 -55 -87 -68 -122 -24 -27 34 -22 96 10 130 29
31 56 30 92 -3 l29 -28 -7 25 c-10 33 -5 30 -61 36 -34 3 -58 0 -78 -11z"/>
<path d="M3365 2465 c-16 -9 -36 -23 -42 -32 -29 -37 -3 -135 38 -148 9 -3 40
-7 69 -8 46 -2 55 1 76 26 37 43 34 107 -7 141 -17 14 -38 26 -47 26 -9 0 -26
3 -37 5 -11 3 -33 -2 -50 -10z m99 -27 c9 -12 16 -41 16 -63 0 -53 -23 -85
-60 -85 -36 0 -60 32 -60 81 0 72 69 117 104 67z"/>
<path d="M3627 2453 c-3 -10 -8 -49 -12 -88 -4 -38 -12 -76 -17 -82 -8 -10 -6
-13 5 -13 10 0 18 14 22 40 4 22 10 42 15 45 4 3 21 -16 37 -41 l30 -46 27 46
c31 53 46 52 46 -5 0 -37 2 -39 31 -39 25 0 30 3 25 17 -4 9 -9 49 -12 87 -8
106 -10 108 -55 38 -22 -34 -43 -62 -48 -62 -5 0 -27 27 -49 60 -31 46 -42 56
-45 43z"/>
<path d="M523 2415 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M3922 2382 c-6 -42 -14 -85 -17 -95 -5 -11 -2 -17 7 -17 9 0 18 17
24 48 l10 47 29 -47 c17 -26 32 -47 35 -47 3 0 18 21 34 47 l29 47 8 -35 c5
-19 7 -40 4 -46 -4 -12 53 -26 59 -15 4 11 -26 191 -33 191 -3 0 -21 -25 -39
-55 -18 -30 -37 -57 -42 -60 -5 -3 -26 22 -47 55 -21 33 -41 60 -44 60 -3 0
-11 -35 -17 -78z"/>
<path d="M4228 2375 c3 -82 4 -86 32 -100 42 -22 95 -19 119 6 26 28 35 179
11 179 -12 0 -14 -13 -12 -74 3 -65 1 -77 -17 -95 -25 -25 -40 -26 -67 -7 -16
12 -19 27 -19 95 0 80 0 81 -25 81 -25 0 -25 0 -22 -85z"/>
<path d="M4488 2361 l-3 -101 28 0 c26 0 27 2 27 55 0 30 3 55 8 55 4 0 33
-25 65 -55 31 -30 59 -55 61 -55 3 0 6 44 8 99 l3 98 -40 0 c-22 0 -34 -2 -27
-4 13 -5 17 -94 5 -107 -4 -3 -35 21 -69 55 l-63 62 -3 -102z"/>
<path d="M4768 2357 l-3 -97 30 0 30 0 0 98 0 99 -27 -1 -27 -1 -3 -98z"/>
<path d="M4921 2421 c-41 -39 -43 -88 -3 -132 51 -57 172 -48 172 14 0 20 -2
19 -26 -10 -31 -41 -65 -43 -93 -7 -25 32 -28 100 -5 132 19 28 67 29 94 2 25
-25 30 -25 30 -1 0 24 -19 31 -85 31 -45 0 -58 -5 -84 -29z"/>
<path d="M5230 2440 c0 -5 -18 -48 -40 -95 -45 -97 -45 -95 -26 -95 8 0 17 11
20 25 5 20 13 25 36 25 23 0 33 -6 40 -25 8 -20 16 -25 43 -25 l34 0 -37 78
c-20 42 -40 87 -44 100 -7 22 -26 31 -26 12z m10 -99 c11 -22 0 -34 -23 -25
-9 3 -13 13 -10 25 7 24 19 24 33 0z"/>
<path d="M5360 2432 c0 -12 3 -13 12 -4 18 18 46 15 52 -6 4 -10 4 -53 2 -95
l-4 -77 29 0 29 0 0 91 0 91 30 -4 c18 -3 30 0 30 7 0 7 -31 11 -90 12 -76 0
-90 -2 -90 -15z"/>
<path d="M5606 2429 c4 -6 6 -50 5 -98 l-2 -86 28 -1 28 -1 0 99 0 98 -33 0
c-22 0 -31 -4 -26 -11z"/>
<path d="M5771 2411 c-26 -24 -31 -37 -31 -73 0 -59 31 -89 98 -96 64 -6 106
16 122 63 25 77 -23 135 -113 135 -36 0 -51 -6 -76 -29z m124 -5 c21 -32 22
-100 1 -131 -37 -57 -101 -12 -101 71 0 74 65 113 100 60z"/>
<path d="M6035 2335 l0 -95 30 0 30 0 -3 56 c-4 52 -3 55 15 46 10 -6 38 -31
63 -56 24 -25 48 -46 52 -46 4 0 8 43 8 95 l0 95 -37 -1 c-21 0 -32 -3 -25 -6
13 -5 18 -103 4 -103 -4 0 -32 25 -62 55 -30 30 -59 55 -65 55 -6 0 -10 -36
-10 -95z"/>
<path d="M6436 2408 c-9 -12 -16 -30 -16 -39 0 -17 60 -69 79 -69 14 0 31 -34
23 -47 -4 -5 -14 -13 -22 -16 -11 -4 -9 -6 6 -6 36 -2 56 21 52 59 -3 30 -10
38 -50 60 -29 15 -48 32 -48 42 0 21 47 35 63 19 15 -15 27 -14 27 4 0 11 -12
15 -49 15 -40 0 -53 -4 -65 -22z"/>
<path d="M6642 2326 c-1 -107 -1 -106 89 -106 40 0 48 3 53 23 10 36 7 40 -11
18 -10 -11 -29 -22 -43 -23 -25 -3 -25 -2 -28 85 l-3 89 35 -3 c19 -2 38 0 42
4 4 4 -24 7 -63 7 l-70 0 -1 -94z"/>
<path d="M6865 2320 l0 -100 28 0 27 0 0 100 0 100 -27 0 -28 0 0 -100z"/>
<path d="M6940 2414 c0 -3 7 -12 15 -20 21 -21 19 -51 -4 -65 -17 -11 -17 -14
-2 -50 16 -38 45 -59 82 -59 19 0 18 3 -10 39 -36 45 -36 47 -16 67 18 19 20
58 3 71 -16 12 -68 26 -68 17z"/>
<path d="M7080 2417 c0 -2 12 -26 27 -52 15 -27 37 -73 49 -103 l22 -55 46 98
c50 106 50 105 38 105 -4 0 -20 -25 -36 -56 -15 -31 -31 -53 -35 -48 -4 5 -16
30 -25 57 -12 33 -23 47 -36 47 -10 0 -25 3 -34 6 -9 3 -16 4 -16 1z"/>
<path d="M7355 2310 l0 -100 25 0 25 0 0 100 0 100 -25 0 -25 0 0 -100z"/>
<path d="M7533 2390 c-62 -37 -72 -103 -24 -151 25 -25 37 -29 84 -29 55 0 87
19 87 51 0 8 -9 2 -21 -13 -15 -21 -29 -28 -53 -28 -41 0 -66 27 -72 77 -6 50
13 85 56 100 l35 12 -30 0 c-16 1 -44 -8 -62 -19z"/>
<path d="M7630 2403 c0 -3 9 -16 20 -30 l20 -26 0 25 c0 17 -7 27 -20 31 -11
3 -20 3 -20 0z"/>
<path d="M7765 2305 l0 -95 33 -6 c18 -4 36 -4 39 0 4 3 -1 6 -10 6 -13 0 -16
14 -16 90 l0 90 28 0 c15 0 32 -5 39 -12 13 -13 27 -5 17 11 -3 6 -34 11 -68
11 l-62 0 0 -95z"/>
<path d="M7996 2385 c-9 -9 -16 -28 -16 -43 0 -21 11 -34 51 -60 57 -37 63
-65 15 -70 -19 -2 -36 4 -52 19 -24 22 -24 22 -24 2 0 -25 18 -33 70 -33 37 0
80 28 80 53 0 21 -23 47 -65 71 -48 29 -52 35 -33 54 17 17 38 15 61 -5 19
-17 19 -17 16 2 -5 27 -79 34 -103 10z"/>
<path d="M6750 2340 c0 -5 -10 -10 -22 -10 -22 -1 -22 -1 -3 -12 11 -6 23 -16
27 -22 4 -6 8 4 8 22 0 17 -2 32 -5 32 -3 0 -5 -4 -5 -10z"/>
<path d="M7850 2301 c0 -5 7 -11 14 -14 10 -4 13 -1 9 9 -6 15 -23 19 -23 5z"/>
<path d="M6420 2264 c0 -25 18 -39 42 -32 9 2 4 12 -14 30 l-28 27 0 -25z"/>
<path d="M7891 2234 c-1 -7 -11 -15 -23 -19 -21 -6 -21 -8 -4 -12 22 -6 40 10
33 29 -3 7 -6 8 -6 2z"/>
<path d="M3435 10 c-4 -6 27 -10 86 -10 51 0 90 2 88 5 -11 10 -168 15 -174 5z"/>
<path d="M3648 3 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3693 3 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M3768 3 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3828 3 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
